var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            icon: 'ni ni-chart-pie-35 text-blue',
            path: '/my-dashboard'
          }}}),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Organization',
            icon: 'ni ni-single-02 text-red',
            path: '/manage-organization'
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Reports',
            icon: 'ni ni-archive-2 text-green',
            path: '/manage-reports'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Clients',
            icon: 'ni ni-archive-2 text-blue',
            path: '/manage-clients'
          }}}),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Checklists',
            icon: 'ni ni-align-left-2 text-default',
            path: '/manage-checklists'
          }}}):_vm._e(),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Parameters',
            icon: 'ni ni-align-left-2 text-default',
            path: '/manage-parameters'
          }}}):_vm._e(),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Equipment',
            icon: 'ni ni-archive-2 text-green',
            path: '/manage-products'
          }}}):_vm._e(),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Equipment Types',
            icon: 'ni ni-archive-2 text-default',
            path: '/manage-all-equipment'
          }}}):_vm._e(),(_vm.user_role=='admin')?_c('sidebar-item',{attrs:{"link":{
            name: 'Users',
            icon: 'ni ni-single-02 text-blue',
            path: '/manage-users'
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Logout',
            icon: 'ni ni-user-run text-default',
            path: '/logout'
          }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }