import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');


// RPA pages
const ManageProducts = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageProducts.vue');
const UpdateProduct = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/UpdateProduct.vue');

const ManageClients = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageClients.vue');
const UpdateClient = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/UpdateClient.vue');


const ManageClient = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageClient.vue');

const AddReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddReport.vue');
const AddAsset = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddAsset.vue');
const AddBuilding = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddBuilding.vue');

const AddReportInit = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddReportInit.vue');


const ViewReports = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ViewReports.vue');
const ViewReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ViewReport.vue');

const ManageChecklists = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageChecklists.vue');
const ManageChecklist = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageChecklist.vue');

const ManageParameters = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageParameters.vue');
const ManageParameter = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageParameter.vue');


const EditReportDetails = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/EditReportDetails.vue');

const Logout = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Logout.vue');

const ManageEquipments = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageEquipments.vue');
const ManageEquipment = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageEquipment.vue');

const UpdateAddress = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/UpdateAddress.vue');

const AddSignature = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddSignature.vue');
const AddMySignature = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AddMySignature.vue');


const ManageAccount = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageAccount.vue');
const AdminConsole = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/AdminConsole.vue');

const ManageOrganization = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageOrganization.vue');
const ManageUsers = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageUsers.vue');
const ManageUser = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageUser.vue');
const ManageReports = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageReports.vue');

const MyDashboard = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/Dashboard.vue');

const ManageReportPhotos = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageReportPhotos.vue');
const ManageEquipmentSubtype = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RP/ManageEquipmentSubtype.vue');




let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
  	  }
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    
    {
 	 path: '/login',
 	 name: 'Login',
 	 component: Login,
 	 meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
	 }
	},
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Login'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      },
       {
     	path: 'manage-products',
        name: 'Manage Products',
        component: ManageProducts
   		},
   		 {
     	path: 'update-product/:pid',
        name: 'Update Product',
        component: UpdateProduct
   	   },
   	  {
     	path: 'manage-clients',
        name: 'Manage Clients',
        component: ManageClients
   	   },
   	   {
     	path: 'manage-client/:cid',
        name: 'Manage Client',
        component: ManageClient
   	   },
   	    {
     	path: 'update-client/:cid',
        name: 'Update Client',
        component: UpdateClient
   	   },
   	    {
     	path: 'add-asset/:cid/building/:bid',
        name: 'Add Asset To Building',
        component: AddAsset
   	   },
   	   {
     	path: 'add-building/:cid/address/:adid',
        name: 'Add Building To Address',
        component: AddBuilding
   	   },
   	   {
     	path: 'add-report/:cid',
        name: 'Add Report',
        component: AddReport
   	   },
   	    {
     	path: 'add-report-init/:cid',
        name: 'Add Report',
        component: AddReportInit
   	   },
   	   {
     	path: 'view-reports/:cid',
        name: 'View Reports',
        component: ViewReports
   	   },
   	    {
     	path: 'view-report/:rid',
        name: 'View Report',
        component: ViewReport
   	   },
   	  {
     	path: 'manage-report-photos/:rid',
        name: 'Report Photos',
        component: ManageReportPhotos
   	   },
   	   {
     	path: 'manage-checklists',
        name: 'Manage Checklists',
        component: ManageChecklists
   	   },
   	   {
     	path: 'manage-checklist/:clid',
        name: 'Manage Checklist',
        component: ManageChecklist
   	   },
   	     {
     	path: 'manage-parameters',
        name: 'Manage Parameters',
        component: ManageParameters
   	   },
   	     {
     	path: 'manage-parameter/:pid',
        name: 'Manage Parameter',
        component: ManageParameter
   	   },
   	    {
     	path: 'add-signature/:rid/:type',
        name: 'Edit Report Details',
        component: AddSignature
   	   },
   	   {
     	path: 'add-my-signature/:rid/:type',
        name: 'Add My Signature',
        component: AddMySignature
   	   },
   	    {
     	path: 'edit-report-details/:rid',
        name: 'Edit Report Details',
        component: EditReportDetails
   	   },
   	    {
     	path: 'update-address/:cid/:aid',
        name: 'Update Address',
        component: UpdateAddress
   	   },
   	   {
     	path: 'logout',
        name: 'Logging Out',
        component: Logout
   		},
   		{
     	path: 'manage-all-equipment',
        name: 'Manage All Equipment',
        component: ManageEquipments
   	   },
   	   {
     	path: 'manage-reports',
        name: 'All Reports',
        component: ManageReports
   	   },
   	   {
     	path: 'manage-equipment/:etid',
        name: 'Manage Equipment',
        component: ManageEquipment
   	   },
   	    {
     	path: 'manage-equipment-subtype/:estid',
        name: 'Manage Equipment Subtype',
        component: ManageEquipmentSubtype
   	   },
   	    {
     	path: 'manage-account',
        name: 'Manage Account',
        component: ManageAccount
   	   },
   	   {
     	path: 'manage-organization',
        name: 'Manage Organization',
        component: ManageOrganization
   	   },
 	   {
     	path: 'manage-users',
        name: 'Manage Users',
        component: ManageUsers
   	   },   	 
   	    {
     	path: 'manage-user/:uid',
        name: 'Manage User',
        component: ManageUser
   	   },  {
     	path: 'sadmin/console',
        name: 'Console',
        component: AdminConsole
   	   },
   	     {
     	path: 'my-dashboard',
        name: 'My Dashboard',
        component: MyDashboard
   	   },  
    ]
  },
  authPages,
];

export default routes;
