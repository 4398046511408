const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
**/
const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY) 
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    },
    
    saveUserInfo(userInfo) {
        localStorage.setItem('user_info', userInfo)
    },
    
    removeUserInfo(userInfo) {
        localStorage.removeItem('user_info')
    },
    
    getUserInfo() {
        return localStorage.getItem('user_info')
    },
	
	saveUserRole(userRole) {
        localStorage.setItem('user_role', userRole)
    },
    
     getUserRole() {
        return localStorage.getItem('user_role')
    },



}

export { TokenService }